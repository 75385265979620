require('./../scss/main.scss');

import {tns} from 'tiny-slider/src/tiny-slider';
if(document.querySelector('.slider')){
    let slider = tns({
        container: '.slider',
        items: 1,
        controls: false,
        nav: false,
        autoplay: true,
        autoplayButton: false,
        autoplayButtonOutput: false
    });
}

let throttle = (callback, limit) => {
    let wait = false;
    return function () {
        if (!wait) {
            callback();
            wait = true;
            setTimeout(function () {
                wait = false;
            }, limit);
        }
    };
};

let debounce = (callback, time) => {
    let timeout;
    return function () {
        let context = this,
            args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            callback.apply(context, args);
        }, time || 200);
    };
};

let changeNavbar = function(){
    let element = document.querySelector('.navbar-main');
    window.scrollY > 1 ? element.classList.add('scroll') : element.classList.remove('scroll');
};

let scrollIt = (destination, duration = 200, easing = 'linear', callback) => {

    const easings = {
        linear(t) {
            return t;
        },
        easeInQuad(t) {
            return t * t;
        },
        easeOutQuad(t) {
            return t * (2 - t);
        },
        easeInOutQuad(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        },
        easeInCubic(t) {
            return t * t * t;
        },
        easeOutCubic(t) {
            return (--t) * t * t + 1;
        },
        easeInOutCubic(t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        },
        easeInQuart(t) {
            return t * t * t * t;
        },
        easeOutQuart(t) {
            return 1 - (--t) * t * t * t;
        },
        easeInOutQuart(t) {
            return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
        },
        easeInQuint(t) {
            return t * t * t * t * t;
        },
        easeOutQuint(t) {
            return 1 + (--t) * t * t * t * t;
        },
        easeInOutQuint(t) {
            return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
        }
    };

    const start = window.pageYOffset;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

    if ('requestAnimationFrame' in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
    }

    function scroll() {
        const now = 'now' in window.performance ? performance.now() : new Date().getTime();
        const time = Math.min(1, ((now - startTime) / duration));
        const timeFunction = easings[easing](time);
        window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

        if (window.pageYOffset === destinationOffsetToScroll) {
            if (callback) {
                callback();
            }
            return;
        }

        requestAnimationFrame(scroll);
    }

    scroll();
};

let navbarToggler = document.querySelector('.navbar-toggler');
navbarToggler.addEventListener('click', (e) => {
    let target = e.currentTarget;
    target.parentElement.parentElement.classList.toggle('menu-show');
    document.querySelector(target.getAttribute('data-target')).classList.toggle('show');

});

let NavItems = document.querySelectorAll('.scroll');

NavItems.forEach(el => el.addEventListener('click', (e) => {
    e.preventDefault();
    let target = e.target;

    document.querySelector('.navbar-main').classList.remove('menu-show');
    document.querySelector('#nav-main').classList.remove('show');

    scrollIt(document.querySelector(target.getAttribute('href')), 400, 'easeInOutCubic');

}));

let directHelpElement = document.querySelector('#direct-help');
directHelpElement.addEventListener('click',evt => {
    if(evt.target){
        let target = evt.target,
        value = target.getAttribute('data-value');

        document.querySelectorAll('#direct-help > button').forEach(el => {
            el.classList.remove('active');
        });
        target.classList.add('active');
        document.querySelector('#direct-help-value').value = value;

    }
});

(function () {

    if(location.hash) {
        window.scrollTo(0, 0);

        let hash = location.hash;

        scrollIt(document.querySelector(hash), 400, 'easeInOutCubic');

    }

    window.addEventListener('scroll', throttle(changeNavbar, 100));
    window.addEventListener('scroll', debounce(changeNavbar, 200));

})();